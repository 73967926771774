<!-- 是否变更账户 -->
<template>
  <el-radio-group v-bind="$attrs" v-on="$listeners">
      <el-radio
        v-for="(item, index) of ($attrs.options)"
        :label="item.value"
        :key="index"
        >{{ item.label }}
      </el-radio>
    </el-radio-group>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
